import { default as volunteersdxXBRxXrw1Meta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/volunteers.vue?macro=true";
import { default as code_45of_45conductRDPbFurFEcMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/code-of-conduct.vue?macro=true";
import { default as contact4LeFZG9Eu6Meta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/contact.vue?macro=true";
import { default as _91slug_936f8Iftd1fQMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/events/[eventType]/[eventDate]/[slug].vue?macro=true";
import { default as indexPAHCjQiAITMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/events/[eventType]/[eventDate]/index.vue?macro=true";
import { default as indexPrgi0By9VPMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/events/[eventType]/index.vue?macro=true";
import { default as indexWWaAIEnkAYMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/events/index.vue?macro=true";
import { default as faq8miDBWutYgMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/faq.vue?macro=true";
import { default as _91slug_93qLSrm4COJBMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/groups/[slug].vue?macro=true";
import { default as indexSAk6ucXsQXMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/index.vue?macro=true";
import { default as _91slug_93RzRaloon6rMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/people/[slug].vue?macro=true";
import { default as indexdWqYYWHIJLMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/people/index.vue?macro=true";
import { default as privacydpJDnFbzfQMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/privacy.vue?macro=true";
import { default as team5jDEInuhgbMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/team.vue?macro=true";
import { default as terms_45and_45conditionsbX2zCs9FSvMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/terms-and-conditions.vue?macro=true";
import { default as termsBm4XQbO4QkMeta } from "/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/terms.vue?macro=true";
export default [
  {
    name: volunteersdxXBRxXrw1Meta?.name ?? "applications-volunteers",
    path: volunteersdxXBRxXrw1Meta?.path ?? "/applications/volunteers",
    meta: volunteersdxXBRxXrw1Meta || {},
    alias: volunteersdxXBRxXrw1Meta?.alias || [],
    redirect: volunteersdxXBRxXrw1Meta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/applications/volunteers.vue").then(m => m.default || m)
  },
  {
    name: code_45of_45conductRDPbFurFEcMeta?.name ?? "code-of-conduct",
    path: code_45of_45conductRDPbFurFEcMeta?.path ?? "/code-of-conduct",
    meta: code_45of_45conductRDPbFurFEcMeta || {},
    alias: code_45of_45conductRDPbFurFEcMeta?.alias || [],
    redirect: code_45of_45conductRDPbFurFEcMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: contact4LeFZG9Eu6Meta?.name ?? "contact",
    path: contact4LeFZG9Eu6Meta?.path ?? "/contact",
    meta: contact4LeFZG9Eu6Meta || {},
    alias: contact4LeFZG9Eu6Meta?.alias || [],
    redirect: contact4LeFZG9Eu6Meta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _91slug_936f8Iftd1fQMeta?.name ?? "events-eventType-eventDate-slug",
    path: _91slug_936f8Iftd1fQMeta?.path ?? "/events/:eventType()/:eventDate()/:slug()",
    meta: _91slug_936f8Iftd1fQMeta || {},
    alias: _91slug_936f8Iftd1fQMeta?.alias || [],
    redirect: _91slug_936f8Iftd1fQMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/events/[eventType]/[eventDate]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexPAHCjQiAITMeta?.name ?? "events-eventType-eventDate",
    path: indexPAHCjQiAITMeta?.path ?? "/events/:eventType()/:eventDate()",
    meta: indexPAHCjQiAITMeta || {},
    alias: indexPAHCjQiAITMeta?.alias || [],
    redirect: indexPAHCjQiAITMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/events/[eventType]/[eventDate]/index.vue").then(m => m.default || m)
  },
  {
    name: indexPrgi0By9VPMeta?.name ?? "events-eventType",
    path: indexPrgi0By9VPMeta?.path ?? "/events/:eventType()",
    meta: indexPrgi0By9VPMeta || {},
    alias: indexPrgi0By9VPMeta?.alias || [],
    redirect: indexPrgi0By9VPMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/events/[eventType]/index.vue").then(m => m.default || m)
  },
  {
    name: indexWWaAIEnkAYMeta?.name ?? "events",
    path: indexWWaAIEnkAYMeta?.path ?? "/events",
    meta: indexWWaAIEnkAYMeta || {},
    alias: indexWWaAIEnkAYMeta?.alias || [],
    redirect: indexWWaAIEnkAYMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: faq8miDBWutYgMeta?.name ?? "faq",
    path: faq8miDBWutYgMeta?.path ?? "/faq",
    meta: faq8miDBWutYgMeta || {},
    alias: faq8miDBWutYgMeta?.alias || [],
    redirect: faq8miDBWutYgMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qLSrm4COJBMeta?.name ?? "groups-slug",
    path: _91slug_93qLSrm4COJBMeta?.path ?? "/groups/:slug()",
    meta: _91slug_93qLSrm4COJBMeta || {},
    alias: _91slug_93qLSrm4COJBMeta?.alias || [],
    redirect: _91slug_93qLSrm4COJBMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/groups/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexSAk6ucXsQXMeta?.name ?? "index",
    path: indexSAk6ucXsQXMeta?.path ?? "/",
    meta: indexSAk6ucXsQXMeta || {},
    alias: indexSAk6ucXsQXMeta?.alias || [],
    redirect: indexSAk6ucXsQXMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RzRaloon6rMeta?.name ?? "people-slug",
    path: _91slug_93RzRaloon6rMeta?.path ?? "/people/:slug()",
    meta: _91slug_93RzRaloon6rMeta || {},
    alias: _91slug_93RzRaloon6rMeta?.alias || [],
    redirect: _91slug_93RzRaloon6rMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/people/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexdWqYYWHIJLMeta?.name ?? "people",
    path: indexdWqYYWHIJLMeta?.path ?? "/people",
    meta: indexdWqYYWHIJLMeta || {},
    alias: indexdWqYYWHIJLMeta?.alias || [],
    redirect: indexdWqYYWHIJLMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/people/index.vue").then(m => m.default || m)
  },
  {
    name: privacydpJDnFbzfQMeta?.name ?? "privacy",
    path: privacydpJDnFbzfQMeta?.path ?? "/privacy",
    meta: privacydpJDnFbzfQMeta || {},
    alias: privacydpJDnFbzfQMeta?.alias || [],
    redirect: privacydpJDnFbzfQMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: team5jDEInuhgbMeta?.name ?? "team",
    path: team5jDEInuhgbMeta?.path ?? "/team",
    meta: team5jDEInuhgbMeta || {},
    alias: team5jDEInuhgbMeta?.alias || [],
    redirect: team5jDEInuhgbMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/team.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionsbX2zCs9FSvMeta?.name ?? "terms-and-conditions",
    path: terms_45and_45conditionsbX2zCs9FSvMeta?.path ?? "/terms-and-conditions",
    meta: terms_45and_45conditionsbX2zCs9FSvMeta || {},
    alias: terms_45and_45conditionsbX2zCs9FSvMeta?.alias || [],
    redirect: terms_45and_45conditionsbX2zCs9FSvMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: termsBm4XQbO4QkMeta?.name ?? "terms",
    path: termsBm4XQbO4QkMeta?.path ?? "/terms",
    meta: termsBm4XQbO4QkMeta || {},
    alias: termsBm4XQbO4QkMeta?.alias || [],
    redirect: termsBm4XQbO4QkMeta?.redirect || undefined,
    component: () => import("/Users/dratushnyy/development/src/IMPRO-Amsterdam/frontend/pages/terms.vue").then(m => m.default || m)
  }
]