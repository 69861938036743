import { defineStore } from 'pinia'

interface NavigationItem {
	name: string
	href?: string
	type?: string
}

export interface Navigation extends NavigationItem {
	submenu?: Navigation[]
}

interface NavigationStore {
	topNavigation: Navigation[]
	bottomNavigation: NavigationItem[]
}

export const useNavigationStore = defineStore({
	id: 'navbar',
	state: (): NavigationStore => ({
		topNavigation: [
			{ name: 'Shows', href: '/events/shows' },
			{ name: 'Workshops', href: '/events/workshops' },
			//{ name: 'Merchandise', href: '/merchandise' },
			{ name: 'Join on stage', href: '/events/join-on-stage' },
			{ name: 'All Events', href: '/events' },
			{ name: 'People', href: '/people' },
			{
				name: 'Volunteers', href: '/applications/volunteers/',
				//submenu: [
					// { name: 'Call for Volunteers', href: '/applications/volunteers/' },
				//],
				// 		{ name: 'Teaching', href: '/applications/teaching/' },
				// 		{ name: 'Established groups', href: '/applications/groups/' },
				// 		{ name: 'New formats', href: '/applications/new_formats/' },
				// 		{ name: 'Jams', href: '/applications/jams/' },
				// 	]
			},
			{
				name: 'About',
				submenu: [
					{ name: 'Team', href: '/team' },
					{ name: 'FAQ', href: '/faq' },
					{ name: 'Terms and Conditions ', href: '/terms-and-conditions' },
					{ name: 'Code of Conduct', href: '/code-of-conduct' },
					{ name: 'Contact', href: '/contact' },
					// { name: 'Become a volunteer', href: '/applications/volunteer' },
				],
			},
		],
		bottomNavigation: [
			{ name: 'Terms and conditions', href: '/terms' },
			{ name: 'Privacy policy', href: '/privacy' },
		],
	}),
})
