<template>
	<header
		:class="{ sticky: !mobileMenuOpen }"
		class="top-0 drop-shadow-2xl site-header header"
	>
		<nav
			class="mx-auto flex max-w-7xl items-center justify-between p-2 lg:px-8"
			aria-label="Global"
		>
			<div class="flex lg:flex-1">
				<a href="/" class="logo -m-1.5 p-1.1">
					<span class="sr-only">IMPRO Amsterdam</span>
					<img class="logo" src="/logo.png" alt="IMPRO Amsterdam" />
				</a>
			</div>
			<div class="flex lg:hidden">
				<button
					type="button"
					class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-indigo-300"
					@click="mobileMenuOpen = true"
				>
					<span class="sr-only">Open main menu</span>
					<Bars3Icon class="h-6 w-6" aria-hidden="true" />
				</button>
			</div>
			<div class="hidden lg:flex lg:gap-x-8">
				<div
					v-for="(item, index) in navigation"
					:key="item.name"
					class="flex items-center relative menu-item"
				>
					<template v-if="item.submenu">
						<div
							:class="{ 'active-sub-link': isMenuOpened[index] }"
							class="flex items-center cursor-pointer rounded-lg py-2 px-4 menu-item__sub-menu"
							@click.stop="toggleSubMenu(index)"
						>
							<span>{{ item.name }}</span>
							<ChevronDownIcon class="block ml-4 h-5 w-5" aria-hidden="true" />
						</div>
						<div
							v-if="isMenuOpened[index]"
							class="menu-item__sub-wrapper absolute top-14 right-0 w-56 rounded-lg"
						>
							<template v-for="subitem in item.submenu" :key="subitem.name">
								<a
									v-if="subitem.type === 'outer'"
									:href="subitem.href"
									class="px-4 block rounded-lg py-2 leading-5 hover:text-stone-950 menu-item__sub-item"
									target="_blank"
									@click="toggleSubMenu()"
								>
									{{ subitem.name }}
								</a>
								<NuxtLink
									v-else
									:to="subitem.href"
									class="px-4 block rounded-lg py-2 leading-7 hover:text-stone-950 menu-item__sub-item"
									active-class="menu-item__sub-item_active"
									exact-active-class="menu-item__sub-item_active"
									@click="toggleSubMenu()"
								>
									{{ subitem.name }}
								</NuxtLink>
							</template>
						</div>
					</template>
					<NuxtLink
						v-else
						:to="item.href"
						class="px-4 -mx-3 block rounded-lg py-2 leading-7 menu-item__link"
						active-class="active-link"
						exact-active-class="exact-active-link"
						@click="toggleSubMenu()"
					>
						{{ item.name }}
					</NuxtLink>
				</div>
			</div>
			<div class="hidden lg:flex lg:flex-1 lg:justify-end"></div>
		</nav>
		<Dialog
			as="div"
			class="lg:hidden"
			:open="mobileMenuOpen"
			@close="mobileMenuOpen = false"
		>
			<div class="fixed inset-0 z-10" />
			<DialogPanel
				class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 mobile-menu"
			>
				<div class="flex items-center justify-between">
					<a href="#" class="-m-1.5 p-1.1">
						<span class="sr-only">IMPRO Amsterdam</span>
						<img
							class="h-8 w-auto logo"
							src="/logo.png"
							alt="IMPRO Amsterdam"
						/>
					</a>
					<button
						type="button"
						class="-m-2.5 rounded-md p-2.5 text-black"
						@click="mobileMenuOpen = false"
					>
						<span class="sr-only">Close menu</span>
						<XMarkIcon class="h-6 w-6" aria-hidden="true" />
					</button>
				</div>
				<div class="mt-6 flow-root">
					<div class="-my-6 divide-y divide-gray-500/10">
						<div class="space-y-2 py-6">
							<template v-for="item in navigation" :key="item.name">
								<NuxtLink
									v-if="item.href"
									:to="item.href"
									class="px-4 -mx-3 block rounded-lg py-2 text-base font-semibold leading-7 text-black hover:text-stone-950 nav-link"
									active-class="active-link"
									exact-active-class="exact-active-link"
									@click="mobileMenuOpen = false"
								>
									{{ item.name }}
								</NuxtLink>
								<template v-else>
									<NuxtLink
										v-for="subitem in item.submenu"
										:key="subitem.name"
										:to="subitem.href"
										class="px-4 -mx-3 block rounded-lg py-2 text-base font-semibold leading-7 text-black hover:text-stone-950 nav-link"
										active-class="active-link"
										exact-active-class="exact-active-link"
										@click="mobileMenuOpen = false"
									>
										{{ subitem.name }}
									</NuxtLink>
								</template>
							</template>
						</div>
						<div class="py-6"></div>
					</div>
				</div>
			</DialogPanel>
		</Dialog>
	</header>
</template>

<script lang="ts" setup>
import { Dialog, DialogPanel } from '@headlessui/vue'
import {
	Bars3Icon,
	XMarkIcon,
	ChevronDownIcon,
} from '@heroicons/vue/24/outline'
import { useNavigationStore } from '@/store/navigation'

import { Navigation } from '@/store/navigation'

const navigationStore = useNavigationStore()

const mobileMenuOpen = ref(false)
const navigation: Ref<Navigation[]> = ref(navigationStore.topNavigation)
const isMenuOpened: Ref<{ [key: number]: boolean }> = ref({})

const hasParentWithClass = (element: Element | null, className: string) => {
	while (element !== null && element.nodeType !== 9) {
		if (element.classList.contains(className)) {
			return true
		}
		element = element.parentElement
	}
	return false
}

const checkMenuHasClickedElement = (event: MouseEvent) => {
	const clickedElement = event.currentTarget
	if (
		clickedElement instanceof Element &&
		!hasParentWithClass(clickedElement, 'menu-item__sub-menu')
	) {
		toggleSubMenu()
		return
	}
	removeBodyClickListener()
}

const setBodyClickListener = () => {
	document.body.addEventListener('click', checkMenuHasClickedElement)
}

const removeBodyClickListener = () => {
	document.body.removeEventListener('click', checkMenuHasClickedElement)
}

const toggleSubMenu = (id = -1) => {
	if (isMenuOpened.value[id] === true) {
		isMenuOpened.value = {}
		removeBodyClickListener()
		return
	}

	isMenuOpened.value = {}
	if (id === -1) {
		removeBodyClickListener()
		return
	}

	isMenuOpened.value[id] = true
	setBodyClickListener()
}
</script>

<style scoped>
.logo {
	display: inline-block;
	width: 120px;
	height: 120px;
}

.header {
	z-index: 100 !important;
	background: url('/header_bg.png') repeat-x 0 0;
	background-size: auto 100%;
}
.active-link {
	/* styles for active link */
	background-color: rgba(242, 145, 32, 0.7) !important;
}

.active-sub-link {
	background-color: rgba(242, 145, 32, 0.3) !important;
}

.exact-active-link {
	/* styles for exact active link */
	background-color: rgba(242, 145, 32, 0.7) !important;
	color: white !important;
	border-radius: 2 !important;
}

.nav-link {
	border-radius: 2 !important;
}

.menu-item {
	font-family: Riverside, sans-serif;
	text-transform: uppercase;
}
.menu-item__link {
	font-size: 20px;
	line-height: 20px;
	color: black;
}
.menu-item__sub-menu {
	font-size: 20px;
	line-height: 20px;
	color: black;
}
.menu-item__sub-wrapper {
	background-color: #e4e4d7;
}
.menu-item__sub-item {
	font-size: 20px;
	line-height: 20px;
	color: black;
}
.menu-item__sub-item:hover {
	background-color: #c7cc00;
}
.menu-item__sub-item_active {
	background-color: #fb600e;
	color: white;
}
.mobile-menu {
	background-color: #e4e4d7;
}
</style>
